import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from './account';

const Status = () => {
  const [status, setStatus] = useState(false);

  const { getSession, logout } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .catch((err)=> {
                console.log(err);
            })
            .then((session) => {
                console.log("Session: ", session);
                if (session) {
                    setStatus(true);
                }
                else {
                    setStatus(false);
                }
            });
    }, []);

  return (
    <>
        {/* <div className='w-full h-screen bg-[#FFDB58] flex items-start'>
        {status &&
            <>
                <h1>Authenticated</h1>
                <button className='w-full text-white font-semibold bg-[#060606] rounded-md p-4 text-center flex items-center justify-center my-2' onClick={logout}>Log out</button>
            </>
        }
        {!status &&
            <>
                <h1>Not authenticated</h1>
                <button className='w-full text-white font-semibold bg-[#060606] rounded-md p-4 text-center flex items-center justify-center my-2' onClick={toLogin}>Log in</button>
            </>
        }
        </div> */}
    </>
  );
};

export default Status;
