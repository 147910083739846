import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { AccountContext } from './account';
import axios from 'axios';


const NewNote = () => {
    const { getSession } = useContext(AccountContext);
    const [userToken, setUserToken] = useState(null);
    const[loading, setLoading] = useState(false);
    const[username, setUsername] = useState(null);
    const[accountInfo, setAccountInfo] = useState(false);
    const[email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const[firstName, setFirstName] = useState('');
    const[lastName, setLastName] = useState('');
    const[license, setLicense] = useState('');
    const[licenseNumber, setLicenseNumber] = useState('');
    const[company, setCompany] = useState('');
    const[address, setAddress] = useState('');
    const[unit, setUnit] = useState('');
    const[city, setCity] = useState('');
    const[state, setState] = useState('');
    const[zipCode, setZipCode] = useState('');
    const[country, setCountry] = useState('');

    const dbName = process.env.REACT_APP_ACCOUNTS_DB_NAME;
    const apiUrl = process.env.REACT_APP_DB_URL;

    const fetchAccount = async () => {
        let response;

        try {
            console.log('fetching account');
            response = await axios.post(`${apiUrl}/get`, {
                TableName: dbName,
                AttributesToGet: ['firstName','lastName','license', 'licenseNumber', 'company', 'address','unit','city','state','zipCode','country', 'email'],
                Key: {
                    PK: {
                        S: username
                    },
                    SK: {
                        S: '-'
                    }
                },
                ConsistentRead: true,
                ReturnConsumedCapacity: 'TOTAL'
            }, {
                headers: {
                    Authorization: userToken
                }
            })

            return response.data;

        } catch(err) {
            setError(err);
        };
    };

    useEffect(() => {
        getSession()
            .catch((err) => {
                setError(err);
            })
            .then((session) => {
                if (session) {
                    const token = session.getIdToken().getJwtToken();
                    const getUsername = session.getAccessToken().payload.sub;
                    const getEmail = session.getIdToken().payload.email;
                    setUserToken(token);
                    setUsername(getUsername);
                    setEmail(getEmail);
                } else navigate('/');
            })

        if (username && userToken) {

            fetchAccount()
                .catch((err) => {
                    setError(err);
                })
                .then((response) => {
                    if (response.Item) {
                        console.log(response.Item);
                        setAccountInfo(true);
                        setFirstName(response.Item.firstName.S);
                        setLastName(response.Item.lastName.S);
                        setLicense(response.Item.license.S);
                        setLicenseNumber(response.Item.licenseNumber.S);
                        setCompany(response.Item.company.S);
                        setAddress(response.Item.address.S);
                        setUnit(response.Item.unit.S);
                        setCity(response.Item.city.S);
                        setState(response.Item.state.S);
                        setZipCode(response.Item.zipCode.S);
                        setCountry(response.Item.country.S);
                        setLoading(false);
                        console.log('account info found');
                    } else {
                        console.log('missing account info');
                    }
                })
        }
    }, [username, userToken, firstName, lastName, license, licenseNumber, company, address, unit, city, country, state, zipCode]);

    return (
        <div className='flex flex-col items-center justify-center bg-white border-black border-2 shadow-[5px_5px_0_0_rgba(0,0,0,1)]'>
            <h1 className='text-3xl text-[#060606] font-extrabold text-center p-8'>New progress note</h1>
            <form className='w-full'>
                <div className='h-full w-full flex flex-col justify-center items-center text-center border-black border-b-2 border-t-2 p-4'>
                    <p>{firstName} {lastName}, {license}</p>
                    <p>{company}</p>
                    <p>{address}, {unit}</p>
                    <p>{city}, {state} {zipCode}</p>
                </div>
                <div className='h-full w-full flex flex-col justify-center items-center text-center border-black border-b-2 p-4'>
                    <p className='text-xs'>This session was conducted via telehealth using audio and/or visual technology.</p>
                    <p className='text-xs'>Client was advised of their rights and responsibilities for the use of telehealth and has consented to its use.</p>
                </div>
                <div className='w-full flex flex-row justify-between items-center text-center border-black border-b-2'>
                    <div className='h-full border-black border-r-2 p-4'>
                        <p className='font-bold'>Diagnosis</p>
                    </div>
                    <div className='h-full p-4'>
                    <p className='text-xs'>The following diagnosis are based on currently available information and may change as additional information becomes available.</p>
                    </div>
                </div>
            <div className='w-full h-full flex justify-center'>
                <button className='h-full font-semibold bg-black text-white p-2' type='submit' value='submit'>Generate</button>
            </div>
            </form>
            {/* <p>{JSON.stringify(accountInfo)}</p> */}
        </div>
    )
}

    
export default NewNote;