import './App.css';
import React, { useContext } from 'react';
import { Account } from './components/account';
import Home from './components/home';
import Status from './components/status';
import Error from './components/error';
import Login  from './components/login';
import Verify from './components/verification';
import Layout from './components/layout';
import Register from './components/register';
import Clients from './components/clients';
import NewNote from './components/newNote';
import AccountInfo from './components/accountInfo';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { AccountContext } from './components/account';

function App() {
  const dispatch = useDispatch();

  return (
    <Account>
      <Status/>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route path='login' element={<Login />} />
            <Route path='register' element={<Register />} />
            <Route path='verify' element={<Verify />} />
            <Route path='account' element={<AccountInfo/>} />
            <Route path='clients' element={<Clients />} />
            <Route path='new-note' element={<NewNote />} />
            <Route path='*' element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Account>
  )
}

export default App;