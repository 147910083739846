import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { Outlet, Link } from "react-router-dom";
import { AccountContext } from './account';

const Layout = () => {
  const { getSession, logout, isAuthenticated } = useContext(AccountContext);
  const { state } = useLocation();
  const [status, setStatus] = useState(isAuthenticated);

  const navigate = useNavigate();

  const toLogin = () => { 
    navigate('/login');
  };

  const toAccount = () => {
    navigate('/account');
  };

  const toClients = () => {
    navigate('/clients');
  };

  const toNewNote = () => {
    navigate('/new-note');
  }

  const sendHome = () => {
    navigate('/');
  };

  const handleLogout = () => {
    logout();
    setStatus(false);
    sendHome();
  }

  useEffect(() => {
    if (isAuthenticated) setStatus(true);
    console.log('Status: ', status);
    getSession()
      .catch((err) => {
        setStatus(false);
      })
      .then((session) => {
        setStatus(session);
    });
  }, [isAuthenticated]);

  return (
    <>
      <nav className='w-screen sticky top-0 h-10 bg-[#FFDB58] z-50 border-b-2 border-black flex flex-row justify-between items-start'>
        <button className='text-black border-r-2 border-black h-full bg-transparent p-4 text-center flex items-center font-black' onClick={sendHome}>Noootes</button>
        {status && 
          <div className='h-full w-full flex items-center justify-end pr-10'>
            <button className='h-full text-center flex items-center p-4' onClick={toNewNote}>New note</button>
            <button className='h-full text-center flex items-center p-4' onClick={toClients}>Clients</button>
            <button className='h-full text-center flex items-center p-4' onClick={toAccount}>Account</button>
          </div>
        }
        <button className='text-white border-l-2 border-black bg-black h-full font-bold p-4 text-center flex items-center' onClick={status ? handleLogout : toLogin}>{status ? 'Logout' : 'Login'}</button>
      </nav>
      <div className='w-full h-screen bg-[#FFDB58] flex items-start'>
        <div className='w-full h-screen bg-transparent flex justify-center items-center'>

              <main>
                  {/* Outlet will render the matched child route component */}
                  <Outlet />
              </main>

              
          </div>
      </div>
      <footer className='w-screen bottom-0 flex justify-center items-center bg-[#FFDB58]'>
        {/* Footer content */}
        <p>Copyright © 2023 Marvin</p>
      </footer>
    </>
  )
};

export default Layout;

/*
  {status &&
          <div>
              <h1>Authenticated</h1>
              <button className='w-full text-white font-semibold bg-[#060606] rounded-md p-4 text-center flex items-center justify-center my-2' onClick={handleLogout}>Log out</button>
          </div>
        } {!status &&
          <div>
              <h1>Not Authenticated</h1>
              <button className='w-full text-white font-semibold bg-[#060606] rounded-md p-4 text-center flex items-center justify-center my-2' onClick={toLogin}>Log in</button>
          </div>
        }
*/