import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { AccountContext } from './account';
import axios from 'axios';


const AccountInfo = () => {
    const { isAuthenticated, getSession } = useContext(AccountContext);
    const[loading, setLoading] = useState(false);
    const[accountInfo, setAccountInfo] = useState(false);
    const [username, setUsername] = useState(null);
    const[email, setEmail] = useState(null);
    const [userToken, setUserToken] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const[firstName, setFirstName] = useState('');
    const[lastName, setLastName] = useState('');
    const[license, setLicense] = useState('');
    const[licenseNumber, setLicenseNumber] = useState('');
    const[company, setCompany] = useState('');
    const[address, setAddress] = useState('');
    const[unit, setUnit] = useState('');
    const[city, setCity] = useState('');
    const[state, setState] = useState('');
    const[zipCode, setZipCode] = useState('');
    const[country, setCountry] = useState('');

    const dbName = process.env.REACT_APP_ACCOUNTS_DB_NAME;
    const apiUrl = process.env.REACT_APP_DB_URL;

    const fetchAccount = async () => {
        let response;

        try {
            console.log('fetching account');
            response = await axios.post(`${apiUrl}/get`, {
                TableName: dbName,
                AttributesToGet: ['firstName','lastName','license', 'licenseNumber', 'company', 'address','unit','city','state','zipCode','country', 'email'],
                Key: {
                    PK: {
                        S: username
                    },
                    SK: {
                        S: '-'
                    }
                },
                ConsistentRead: true,
                ReturnConsumedCapacity: 'TOTAL'
            }, {
                headers: {
                    Authorization: userToken
                }
            })

            return response.data;

        } catch(err) {
            setError(err);
        };
    };

    const updateAccount = async (e) => {
        e.preventDefault();

        try {
            console.log('updating account');
            const response = await axios.post(`${apiUrl}/update`, {
                TableName: dbName,
                Key: {
                    PK: {
                        S: username
                    },
                    SK: {
                        S: '-'
                    }
                },
                UpdateExpression: 'set firstName = :fn, lastName = :ln, license = :lc, licenseNumber = :lcnum, company = :com, address = :ad, #u = :un, city = :ct, #st = :st, zipCode = :zc, country = :co',
                ExpressionAttributeValues: {
                    ':fn': { 'S': firstName },
                    ':ln': { 'S': lastName },
                    ':lc': { 'S': license },
                    ':lcnum': { 'S': licenseNumber },
                    ':com': { 'S': company },
                    ':ad': { 'S': address },
                    ':un': { 'S': unit },
                    ':ct': { 'S': city },
                    ':st': { 'S': state },
                    ':zc': { 'S': zipCode },
                    ':co': { 'S': country },
                },
                ExpressionAttributeNames: {
                    '#u': 'unit',
                    '#st': 'state'
                },
                ReturnValues: 'UPDATED_NEW'
            }, {
                headers: {
                    Authorization: userToken
                }
            });
            console.log(response.data);
        } catch(err) {
            setError(err.message);
        };
    };

    const newAccount = async () => {
        try {
            console.log('creating new account');
            let response = await axios.post(`${apiUrl}/new`, {
                TableName: dbName,
                Item: {
                    PK: { 'S': username },
                    SK: { 'S': '-' },
                    firstName: { 'S': firstName },
                    lastName: { 'S': lastName },
                    license: { 'S': license },
                    licenseNumber: { 'S': licenseNumber },
                    company: { 'S': company },
                    address: { 'S': address },
                    'unit': { 'S': unit },
                    city: { 'S': city },
                    'state': { 'S': state },
                    zipCode: { 'S': zipCode },
                    country: { 'S': country },
                    email: { 'S': email }
                }
            }, {
                headers: {
                    Authorization: userToken
                }
            });
            console.log(JSON.stringify(response));
            
        } catch(err) {
            setError(err.message);
        }
    };

    const cancel = () => {
        navigate('/'); 
    };

    useEffect(() => {
        console.log('Authenticated: ', isAuthenticated);
        getSession()
            .catch((err) => {
                setError(err);
            })
            .then((session) => {
                if (session) {
                    const token = session.getIdToken().getJwtToken();
                    const getUsername = session.getAccessToken().payload.sub;
                    const getEmail = session.getIdToken().payload.email;
                    setUserToken(token);
                    setUsername(getUsername);
                    setEmail(getEmail);
                } else navigate('/');
            })

        if (username && userToken && !loading && !accountInfo) {
            setLoading(true);

            fetchAccount()
                .catch((err) => {
                    setError(err);
                })
                .then((response) => {
                    if (response.Item) {
                        setAccountInfo(true);
                        setFirstName(response.Item.firstName.S);
                        setLastName(response.Item.lastName.S);
                        setLicense(response.Item.license.S);
                        setLicenseNumber(response.Item.licenseNumber.S);
                        setCompany(response.Item.company.S);
                        setAddress(response.Item.address.S);
                        setUnit(response.Item.unit.S);
                        setCity(response.Item.city.S);
                        setState(response.Item.state.S);
                        setZipCode(response.Item.zipCode.S);
                        setCountry(response.Item.country.S);
                        console.log('account info found');
                    } else {
                        console.log('missing account info');
                    }
                })
        }

        if (loading && !accountInfo) {
            console.log('creating new account info');
            newAccount()
                .catch((err) => {
                    setError(err);
                })
                .then((response) => {
                    console.log('new account info created');
                })
        }

    }, [username, email, userToken, firstName, lastName, license, licenseNumber, address, unit, city, country, state, zipCode, isAuthenticated]);

    return (
        <div className='flex flex-col items-center justify-center bg-white border-black border-2 pt-8 shadow-[5px_5px_0_0_rgba(0,0,0,1)]'>
            
            <h1 className='text-3xl text-[#060606] font-extrabold text-center pb-6'>Account</h1>
            <form className='w-full' onSubmit={updateAccount}>
                <div className='w-full'>
                    <label className='h-full border-black border-r-2 font-semibold border-t-2 border-b-2 p-2' htmlFor='firstName'>First name</label>
                    <input
                        type='text'
                        value={firstName}
                        placeholder='First name'
                        onChange={(e) => setFirstName(e.target.value)}
                        className='h-full border-black border-t-2 border-b-2 p-2 outline-none'
                    />
                </div>
                <div className='w-full'>
                    <label className='h-full w-24 border-black font-semibold border-r-2 border-b-2 p-2' htmlFor='firstName'>Last name</label>
                    <input
                        type='text'
                        value={lastName}
                        placeholder='Last name'
                        onChange={(e) => setLastName(e.target.value)}
                        className='h-full border-black border-b-2 p-2 outline-none'
                    />
                </div>
                <div className='w-full border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>License</label>
                    <input
                        type='text'
                        value={license}
                        placeholder='License'
                        onChange={(e) => setLicense(e.target.value)}
                        className='h-full border-black p-2 outline-none'
                    />
                </div>
                <div className='w-full border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>License #</label>
                    <input
                        type='text'
                        value={licenseNumber}
                        placeholder='License'
                        onChange={(e) => setLicenseNumber(e.target.value)}
                        className='h-full border-black p-2 outline-none'
                    />
                </div>
                <div className='w-full border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>Company</label>
                    <input
                        type='text'
                        value={company}
                        placeholder='License'
                        onChange={(e) => setCompany(e.target.value)}
                        className='h-full border-black p-2 outline-none'
                    />
                </div>
                <div className='w-full border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>Address</label>
                    <input
                        type='text'
                        value={address}
                        placeholder='Address'
                        onChange={(e) => setAddress(e.target.value)}
                        className='h-full border-black p-2 outline-none'
                    />
                </div>
                <div className='w-full border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>Unit</label>
                    <input
                        type='text'
                        value={unit}
                        placeholder='Unit'
                        onChange={(e) => setUnit(e.target.value)}
                        className='h-full border-black p-2 outline-none'
                    />
                </div>
                <div className='w-full border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>City</label>
                    <input
                        type='text'
                        value={city}
                        placeholder='City'
                        onChange={(e) => setCity(e.target.value)}
                        className='h-full border-black p-2 outline-none'
                    />
                </div>
                <div className='w-full border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>State</label>
                    <input
                        type='text'
                        value={state}
                        placeholder='State'
                        onChange={(e) => setState(e.target.value)}
                        className='h-full border-black p-2 outline-none'
                    />
                </div>
                <div className='w-full border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>Zip</label>
                    <input
                        type='text'
                        value={zipCode}
                        placeholder='Zip'
                        onChange={(e) => setZipCode(e.target.value)}
                        className='h-full border-black p-2 outline-none'
                    />
                </div>
                <div className='w-full border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>Country</label>
                    <input
                        type='text'
                        value={country}
                        placeholder='Country'
                        onChange={(e) => setCountry(e.target.value)}
                        className='h-full border-black p-2 outline-none'
                    />
                </div>
                <div className='w-full flex flex-row border-black border-b-2'>
                    <label className='h-full border-black font-semibold border-r-2 p-2' htmlFor='firstName'>E-mail</label>
                    <input
                        type='text'
                        value={email}
                        placeholder='E-mail'
                        className='h-full flex-grow border-black p-2 outline-none'
                    />
                </div>
            <div className='w-full h-full flex justify-between'>
                <button className='w-1/2 font-semibold` p-2' onClick={cancel}>Cancel</button>
                <button className='h-full font-semibold w-1/2 bg-black text-white p-2' type='submit' value='submit'>Save</button>
            </div>
            </form>
            {/* <p>{JSON.stringify(accountInfo)}</p> */}
        </div>
    )
}

export default AccountInfo;