import React, { createContext, useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import CognitoUserPool from '../store/cognito';
const AccountContext = createContext();

const Account = (props) => {
    const [ isAuthenticated, setIsAuthenticated ] = useState(false);

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = CognitoUserPool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                    reject();
                    } else {
                    resolve(session);
                    }
                });
            } else {
                reject();
            }
        });
    };
    
    const authenticate = async (Username, Password) => {
        console.log('Username: ', Username, 'Password: ', Password)
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username:Username, Pool:CognitoUserPool });

            const authDetails = new AuthenticationDetails({ Username, Password });

            user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log('onSuccess: ', data);
                setIsAuthenticated(true);
                resolve(data);
            },
            onFailure: (err) => {
                console.error('onFailure: ', err);
                reject(err);
            },
            newPasswordRequired: (data) => {
                console.log('newPasswordRequired: ', data);
                resolve(data);
            },
            });
        });
    };

    const logout = () => {
        const user = CognitoUserPool.getCurrentUser();
        if (user) {
            user.signOut();
            setIsAuthenticated(false);
            console.log('User logged out');
        };
    };
    
    return (
        <AccountContext.Provider value={{ authenticate, isAuthenticated, logout, getSession }}>
            {props.children}
        </AccountContext.Provider>
    );
};

export { Account, AccountContext };