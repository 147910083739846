import { AccountContext } from './account';
import React, { useEffect, useState, useContext } from 'react';
import GOOGLE_LOGO from '../assets/google-logo.png';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import CognitoUserPool from '../store/cognito';
import { useNavigate, Navigate } from 'react-router-dom';

const color_scheme = {
    primary: '#060606',
    background: '#E0E0E0',
    disabled: '#D9D9D9'
}

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const { authenticate, isAuthenticated } = useContext(AccountContext);

    useEffect(() => {},[])

    const handleSubmit = (event) => {
        event.preventDefault();
        
        authenticate(email, password)

            .then((data) => {
                console.log('Authenticated');
            })
            .catch((err) => {
                console.log('Not Authenticated: \n', err);
                setLoginError('Incorrect e-mail or password');
            })
      };

    const toRegister = () => {
        navigate('/register');
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        };

    },[isAuthenticated]);

    return (
        <div className='w-full h-screen bg-[#FFDB58] flex items-start'>
            <div className='w-full min-h-screen flex justify-center items-center'>
                <div className='relative 2xl:min-w-1/4 lg:min-w-1/2 md:min-w-1/2 max-sm:w-screen border border-black shadow-[5px_5px_0_0_rgba(0,0,0,1)] h-full bg-white flex flex-col justify-between items-center'>
                    <div className='w-full flex flex-col p-20'>
                        <h1 className='text-5xl text-[#060606] font-extrabold text-center'>Progress Notes</h1>
                        <h3 className='text-4xl text-[#bfbfbf] text-center pt-5'>Made easy</h3>
                        <div className='w-full flex flex-col mb-5'>
                            <h3 className='text-3xl font-semibold text-[#060606] mb-2 pt-20'>Login</h3>
                            <p className='text-md mb-2'>Welcome back! Please enter your details.</p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='w-full flex flex-col'>
                                <input 
                                    type='email'
                                    value={email}
                                    placeholder='Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    className='w-full text-[#060606] bg-transparent border-b border-[#060606] outline-none focus:outline-none py-2 my-2'/>
                                <input 
                                    type='password'
                                    value={password}
                                    placeholder='Password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    className='w-full text-[#060606] bg-transparent border-b border-[#060606] outline-none focus:outline-none py-2 my-2'/>
                            </div>
                            <p className='text-sm text-red-600 text-center'>{loginError}</p>
                            <div className='w-full flex items-center justify-between'>
                                <p className='text-sm font-medium whitespace-nowrap cursor-pointer underline underline-offset-2 py-4'>Forgot password?</p>
                            </div>
                            <div className='w-full flex flex-col my-5'>
                                <button className='w-full text-white font-semibold bg-[#060606] rounded-md p-4 text-center flex items-center justify-center my-2' type='submit' value='submit'>Log In</button>
                            </div>
                        </form>
                        <div className='w-full flex flex-col'>
                            <button className='w-full text-[#060606] font-semibold bg-white border border-black rounded-md p-4 text-center flex items-center justify-center' onClick={toRegister}>Register</button>
                        </div>
                        <div className='w-full flex items-center justify-center relative py-10'>
                            <div className='w-full h-[1px] bg-black'/>
                            <div className='absolute bg-white pl-[10px] pr-[10px]'>
                                <p className='text-lg text-black/80'> or </p>
                            </div>
                        </div>
                        <div className='w-full text-[#060606] font-semibold bg-white border border-black/25 rounded-md p-4 text-center flex items-center justify-center'>
                            <img src={GOOGLE_LOGO} className='h-6 mr-2 bg-transparent'/>
                            <p>Sign In With Google</p>
                        </div>
                        <div className='w-full flex items-center justify-center py-10'>
                            <p className='text-sm font-normal text-[#060606]'>Don't have an account? <span className='font-semibold underline underline-offset-2 cursor-pointer'>Sign up for free</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;