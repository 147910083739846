import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { AccountContext } from './account';
import axios from 'axios';


const Clients = () => {
    const { isAuthenticated, getSession } = useContext(AccountContext);
    const [userToken, setUserToken] = useState(null);
    const[username, setUsername] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const dbName = process.env.REACT_APP_CLIENTS_DB_NAME;
    const apiUrl = process.env.REACT_APP_DB_URL;

    const fetchClient = async (e) => {
        e.preventDefault();
        let response;

        try {
            console.log('fetching client');
            response = await axios.post(`${apiUrl}/get`, {
                TableName: dbName,
                AttributesToGet: [],
                Key: {
                    PK: {
                        S: '-'
                    },
                    SK: {
                        S: '-'
                    }
                },
                ConsistentRead: true,
                ReturnConsumedCapacity: 'TOTAL'
            }, {
                headers: {
                    Authorization: userToken
                }
            })

            return response.data;

        } catch(err) {
            setError(err);
        };
    };

    const updateClient = async (e) => {
        e.preventDefault();

        try {
            console.log('updating client');
            const response = await axios.post(`${apiUrl}/update`, {
                TableName: dbName,
                Key: {
                    PK: {
                        S: '-'
                    },
                    SK: {
                        S: '-'
                    }
                },
                UpdateExpression: 'set',
                ExpressionAttributeValues: {},
                ExpressionAttributeNames: {},
                ReturnValues: 'UPDATED_NEW'
            }, {
                headers: {
                    Authorization: userToken
                }
            });
            console.log(response.data);
        } catch(err) {
            setError(err.message);
        };
    };

    const deleteClient = async (e) => {
        e.preventDefault();

        try {

        } catch(err) {
            setError(err.message);
        }
    };

    const newClient = async () => {
        try {
            console.log('creating new client');
            let response = await axios.post(`${apiUrl}/new`, {
                TableName: dbName,
                Item: {
                    PK: { 'S': '-' },
                    SK: { 'S': '-' }
                }
            }, {
                headers: {
                    Authorization: userToken
                }
            });
            console.log(JSON.stringify(response));
            
        } catch(err) {
            setError(err.message);
        }
    };

    const cancel = () => {
        navigate('/'); 
    };

    useEffect(() => {
        getSession()
            .catch((err) => {
                setError(err);
            })
            .then((session) => {
                if (session) {
                    const token = session.getIdToken().getJwtToken();
                    const getUsername = session.getAccessToken().payload.sub;
                    setUserToken(token);
                    setUsername(getUsername);
                } else navigate('/');
            })
    }, [username, userToken]);

    return (
        <div>
            <h1>Coming soon</h1>
        </div>
    )
}

export default Clients;