import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import CognitoUserPool from '../store/cognito';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';

const color_scheme = {
    primary: '#060606',
    background: '#E0E0E0',
    disabled: '#D9D9D9'
}


const Verify = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [allowed, setAllowed] = useState(false);
    const [verificationCode, setVerificationCode] = useState();
    const [verificationCodeError, setVerificationCodeError] = useState();
    const [verificationMessage, setVerificationMessage] = useState();
    console.log('State: ', state)
    const username = state ? state.username : null;
    const cognitoUser = state ? new CognitoUser({ Username: username, Pool: CognitoUserPool }) : null;    
    console.log(allowed)
    
    useEffect(() => {
        if (!username) {
            navigate('/register');
        };

    },[username]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        cognitoUser.confirmRegistration(verificationCode, true, function (err, result) {
            if (err) {
                if (err.name === 'CodeMismatchException') {
                    setVerificationCodeError('Invalid code');
                } else {
                    setVerificationCodeError('Something went wrong. Please try again later');
                }
            } else {
                console.log(result);
                setSuccess(true);
            }
        })
    };

    const resendConfirmationCode = async (e) => {
        e.preventDefault();
        reset();

        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                console.log(err);
                if (err.name === 'LimitExceededException') {
                    setVerificationCodeError('Attempt limit exceeded. Please try again later');
                } else {
                    setVerificationCodeError('Something went wrong. Please try again later');
                }
            } else {
                console.log(result);
                setVerificationMessage('Confirmation code sent');
            }
        })
    };

    const reset = () => {
        setVerificationCode('');
        setVerificationCodeError('');
        setVerificationMessage('');
    };

    return (
        <div className='w-full h-screen bg-[#FFDB58] flex items-start'>
            <div className='w-full min-h-screen flex justify-center items-center'>
                <div className='relative 2xl:min-w-1/4 lg:min-w-1/2 md:min-w-1/2 max-sm:w-screen border border-black shadow-[5px_5px_0_0_rgba(0,0,0,1)] h-full bg-white flex flex-col justify-between items-center'>
                    <div className='w-full flex flex-col p-20'>
                        <h1 className='text-5xl text-[#060606] font-extrabold text-center'>Progress Notes</h1>
                        <h3 className='text-4xl text-[#bfbfbf] text-center pt-5'>Made easy</h3>
                        {!success && 
                            <>
                                <form onSubmit={handleSubmit}>
                                    <div className='w-full flex flex-col mb-5'>
                                        <h3 className='text-3xl font-semibold text-[#060606] mb-2 pt-20'>Verification</h3>
                                        <p className='text-md mb-2'>Please enter the verification code sent to your e-mail.</p>
                                    </div>
                                    <div className='w-full flex flex-col'>
                                        <input 
                                            type='number'
                                            value={verificationCode}
                                            placeholder='Verification code'
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            className='w-full text-[#060606] bg-transparent border-b border-[#060606] outline-none focus:outline-none py-2 my-2'/>
                                        <p className='text-sm text-red-600 text-center py-2 m-2'>{verificationCodeError}</p>
                                    </div>
                                    <div className='w-full flex flex-col my-5'>
                                        <button className='w-full text-white font-semibold bg-[#060606] rounded-md p-4 text-center flex items-center justify-center my-2 ' type='submit' value='submit'>Confirm</button>
                                    </div>
                                </form>
                                <form onSubmit={resendConfirmationCode}>
                                    <div className='w-full flex flex-col'>
                                        <button className='w-full text-[#060606] font-semibold bg-white border border-black rounded-md p-4 text-center flex items-center justify-center' type='submit' value='submit'>Resend</button>
                                        <p className='text-sm text-[#898989] text-center py-2 m-2'>{verificationMessage}</p>
                                    </div>
                                </form>
                                <div className='w-full flex items-center justify-center py-10'>
                                    <p className='text-sm font-normal text-[#060606]'>Back to <span className='font-semibold underline underline-offset-2 cursor-pointer'>Registration</span></p>
                                </div>
                            </>
                        }
                        {success &&
                        <>
                            <div className='w-full flex flex-col mb-5'>
                                <h3 className='text-3xl font-semibold text-[#060606] mb-2 pt-20 text-center'>Success!</h3>
                                <p className='text-md mb-2 text-center'>You can now log in</p>
                            </div>
                            <div className='w-full flex items-center justify-center py-10'>
                                <p className='text-sm font-normal text-[#060606]'>Back to <Link className='font-semibold underline underline-offset-2 cursor-pointer' to='/login'>Log In</Link></p>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Verify;