import { useEffect, useState } from 'react';
import CognitoUserPool from '../store/cognito';
import GOOGLE_LOGO from '../assets/google-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

const color_scheme = {
    primary: '#060606',
    background: '#E0E0E0',
    yellow: '#F4D738'
}

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [emailError, setEmailError] = useState();
    const [passwordError, setPasswordError] = useState();
    const [passwordMatchError, setPasswordMatchError] = useState();
    const [errors, setErrors] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!email.includes('@') || !email.includes('.')) {
            setEmailError('Invalid email address')
        } else setEmailError();

        if (password.length < 8 && password) {
            setPasswordError('Password must be at least 8 characters long')
        } else setPasswordError();

        if (password !== confirmPassword) {
            setPasswordMatchError('Passwords don\'t match')
        } else setPasswordMatchError();

    },[email, password, confirmPassword, errors])

    const reset = () => {
        setEmail('');
        setPassword('');
        setConfirmPassword('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        setErrors([]);

        CognitoUserPool.signUp(email, password, [], null, (err, data) => {
            if (err) {
              console.error(err);
              reset();
            }
            else {
                console.log({state: {
                    username: email
                }});
                navigate('/verify', {
                    state: {
                        username: email
                    }
                });
            }
        });
       
    };

    const toLogin = () =>{ 
        navigate('/login');
    };

    return (
        <div className='w-full h-screen bg-[#FFDB58] flex items-start'>
        <div className='w-full min-h-screen flex justify-center items-center'>
            <div className='relative 2xl:min-w-1/4 lg:min-w-1/2 md:min-w-1/2 max-sm:w-screen border border-black shadow-[5px_5px_0_0_rgba(0,0,0,1)] h-full bg-white flex flex-col justify-between items-center'>
                <div className='w-full flex flex-col p-20'>
                    <h1 className='text-5xl text-[#060606] font-extrabold text-center'>Progress Notes</h1>
                    <h3 className='text-4xl text-[#bfbfbf] text-center pt-5'>Made easy</h3>
                    <div className='w-full flex flex-col mb-5'>
                            <h3 className='text-3xl font-semibold text-[#060606] mb-2 pt-20'>Register</h3>
                        <p className='text-base mb-2'>Welcome! Please enter your details.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='w-full flex flex-col'>
                                <input 
                                    type='email'
                                    value={email}                                            
                                    placeholder='Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    className='w-full text-[#060606] bg-transparent border-b border-[#060606] outline-none focus:outline-none py-2 my-2'/>
                                <p className='text-sm text-red-600 text-center'>{emailError}</p>
                                <input 
                                    type='password'
                                    value={password}
                                    placeholder='Password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    className='w-full text-[#060606] bg-transparent border-b border-[#060606] outline-none focus:outline-none py-2 my-2'/>
                                <p className='text-sm text-red-600 text-center'>{passwordError}</p>
                                <input 
                                    type='password'
                                    placeholder='Confirm Password'
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className='w-full text-[#060606] bg-transparent border-b border-[#060606] outline-none focus:outline-none py-2 my-2'/>
                                <p className='text-sm text-red-600 text-center'>{passwordMatchError}</p>
                            </div>
                            <div className='w-full flex flex-col my-5'>
                                <button className='w-full text-white font-semibold bg-[#060606] rounded-md p-4 text-center flex items-center justify-center my-2' type='submit' value='submit'>Register</button>
                            </div>
                        </form>
                        <div className='w-full flex flex-col'>
                            <button className='w-full text-[#060606] font-semibold bg-white border border-black p-4 text-center flex items-center justify-center' onClick={toLogin}>Sign In</button>
                        </div>
                        <div className='w-full flex items-center justify-center relative py-10'>
                            <div className='w-full h-[1px] bg-black'/>
                            <p className='text-lg absolute text-black/80 bg-white p-2'>or</p>

                        </div>
                        <div className='w-full text-[#060606] font-semibold bg-white border border-black/25 p-4 text-center flex items-center justify-center'>
                            <img src={GOOGLE_LOGO} className='h-6 mr-2 bg-transparent'/>
                            <p>Sign In With Google</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register;